import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
import { myAxios } from "../../library/networkFunction";

import Form from "react-bootstrap/Form";
import { removeme_api } from "../../library/form_api";
import Joi from "joi";
export default function CaliforniaPrivacyNotice(props) {
  const { show, hide } = props;
  const [request, setRequest] = useState({});

  const submitRemove = async () => {
    const schema = Joi.object({
      email: Joi.string()
        .trim()
        .required()
        .email({ tlds: { allow: false } }),
    });
    const obj = schema.validate(request, { abortEarly: false });
    let { error } = obj;
    if (error) {
      alert("Email not found");
      return false;
    }

    const url = `${removeme_api}/`;
    const response = await myAxios({
      method: "post",
      url,
      request,
    });
    const { status } = response.data;
    if (status == 200) {
      alert(`Please note that it can take up to 10 working days for our
      affiliates to update their system `);
      hide();
    } else alert("please try again");
  };
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>California Privacy Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            The California Consumer Privacy Act ("CCPA") grants California
            residents the right to stop the sale of their personal information.
            If you are a California resident and would like for us to not sell
            your information, please submit your email address below.
          </p>
          <p className="text-justify">
            Alternatively, you may exercise this right by contacting us on our
            website at the Remove Me page.
            {/* For additional information regarding
            your privacy rights please reference our Privacy Policy. */}
          </p>
          {/* <Form.Group className="mb-3">
            <Form.Control
              // className={error["email"] ? "val_error" : ""}
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setRequest((request) => {
                  request["email"] = e.target.value;
                  return request;
                });
              }}
            />
          </Form.Group>
          <div className="row mt-3 mb-3">
            <div className="col text-center">
              <Button
                // variant="primary"
                type="submit"
                onClick={submitRemove}
                className="mb-3 submit-button"
                // style={{
                //   borderRadius: "10px",
                //   boxShadow: "0px 0px 5px #81942c",
                //   width: "190px",
                //   height: "50px",
                //   background: "#81942c",
                //   color: "#fff",
                //   textDecoration: "none",
                //   border: "none",
                // }}
              >
                Submit
              </Button>
            </div>
          </div>
          <p>
            Please note that it can take up to 10 working days for our
            affiliates to update their system
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
