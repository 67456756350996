import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function TermsConditions(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            These Terms and Conditions are applicable to you when using the The
            Credit Lending ® website (hereinafter "Service") or loan connection
            service (hereinafter "Service").
          </p>
          <h3>Terms & Conditions and Terms of Use</h3>
          <p className="text-justify">
            When using this Service, you agree to be bound by the terms and
            conditions below. Please read these carefully. If you do not agree
            to these terms and conditions, please do not access or use this
            Service. This Service is operated by The Credit Lending ®.
          </p>
          <h3>Services and Products on offer</h3>
          <p className="text-justify">
            You understand and agree that The Credit Lending ® is not a lender
            and is not the creditor for or issuer of the products featured on
            this Service. Additional services or products shown on this Service
            after you have received a loan are offered by and remain the sole
            responsibility of the respective product vendors and service
            providers.
          </p>
          <h3>Credit Authorization</h3>
          <p className="text-justify">
            You acknowledge, agree, and authorize that (a) The Credit Lending ®
            will obtain consumer and related information about you from one or
            more consumer reporting agencies for the purposes of connecting you
            with lenders and/or service providers, (b) your information may be
            sent to lenders and/or service providers on your behalf, (c) your
            information may be used in order to make sure you are who you say
            you are, and to determine how much debt you currently have in order
            to determine your debt-to-income ratio, (d) such lenders and/or
            service providers may obtain consumer reports and related
            information about you from one or more consumer reporting agencies,
            such as TransUnion, Experian and Equifax, (e) and your information
            may be used by lenders and/or service providers to make
            prequalification and other credit decisions.
          </p>
          <h3>Security</h3>
          <p className="text-justify">
            Every endeavor is made to protect your personal information however,
            we cannot guarantee that personal information will not be
            intercepted or decrypted by others. We accept no responsibility for
            such interception or decryption.
          </p>
          <h3>Cookies</h3>
          <p className="text-justify">
            This Service utilizes "cookies" and web server logs to collect
            information regarding the usage of this Service. This may include
            the date and time of visits, the pages viewed, time spent at our
            Service, and the website visited just prior and just following our
            website. None of this information is associated with you as an
            individual. "Cookies" are a feature of web browser software that
            allows web servers to recognize the computer used to access a
            website. Cookies are small pieces of data that are stored by a
            user's web browser on the user's hard drive. Cookies can remember
            what information a user accesses on one web page to simplify
            subsequent interactions with that website by the same user or to use
            the information to streamline the user's transactions on related web
            pages. This makes it easier for a user to move from web page to web
            page and to complete commercial transactions over the Internet.
            Cookies should make your online experience easier and more
            personalized.
          </p>
          <h3>Service Use</h3>
          <p className="text-justify">
            This Service is a free service to you. We may receive compensation
            from Suppliers for goods, facilities and services that we may
            provide to them under separate contact. Such goods, facilities or
            services may or may not relate in any way to your use of our
            Service. You agree to any such compensation arrangement whether or
            not related in any way to your use of our Service.
          </p>
          <h3>Limitation of Liabilities and Warranties</h3>
          <p className="text-justify">
            THIS SERVICE AND THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
            ASSOCIATED WITH IT ARE PROVIDED "AS IS". The Credit Lending ®
            DISCLAIMS ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS
            TO ANY MATTER WHATSOEVER RELATING TO THIS SERVICE AND ANY
            INFORMATION, SOFTWARE, PRODUCTS OR SERVICES PROVIDED HEREIN,
            INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE,
            NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
            PURPOSE. The Credit Lending ® MAKES NO WARRANTY AS TO THE RESULTS
            THAT MAY BE ACHIEVED BY USING THIS SERVICE AND THE INFORMATION,
            SOFTWARE, PRODUCTS AND SERVICES ASSOCIATED WITH IT. USE OF THIS
            SERVICE AND/OR The Credit Lending ® SERVICES IS AT YOUR OWN RISK.
            The Credit Lending ® AND/OR ITS PARTICIPATING LENDERS OR ADVERTISERS
            OR ANY OF THEIR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES,
            AFFILIATES, EMPLOYEES, SUCCESSORS, ASSIGNS, CONTENT PROVIDERS, OR
            SERVICE PROVIDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES OR OTHER
            INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS
            SERVICE AND/OR The Credit Lending ® SERVICES OR WITH THE DELAY OR
            INABILITY TO USE THIS SERVICE, OR FOR ANY INFORMATION, SOFTWARE,
            PRODUCTS AND SERVICES OBTAINED THROUGH THIS SERVICE, OR OTHERWISE
            ARISING OUT OF THE USE OF THIS SERVICE, WHETHER RESULTING IN WHOLE
            OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE,
            STRICT LIABILITY OR OTHERWISE, EVEN IF The Credit Lending ® OR ITS
            PARTICIPATING LENDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF
            DAMAGES. SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY AND
            DISCLAIMER OF IMPLIED WARRANTIES, SO THE DISCLAIMERS AND LIMITATIONS
            ABOVE MAY NOT APPLY TO YOU. IN SUCH STATES, THE RESPECTIVE LIABILITY
            OF The Credit Lending ®, ITS PARTICIPATING LENDERS, AND THEIR
            OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, AFFILIATES, EMPLOYEES,
            SUCCESSORS, ASSIGNS, CONTENT PROVIDERS AND SERVICE PROVIDERS IS
            LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
          <h3>Indemnity</h3>
          <p className="text-justify">
            As a condition of your use of this Service, you agree to indemnify
            The Credit Lending ® and any company that provides services in the
            operation of this Service, from and against any and all damages,
            liabilities, losses, costs or expenses (including attorneys' fees)
            arising out of, relating to or resulting from your use or attempted
            use of this Service.
          </p>
          <h3>Disputes and Resolution</h3>
          <p className="text-justify">
            If you have any dispute concerning any aspect of these Terms of
            Website Use, the Website, or any of our services, you agree to
            submit your dispute for resolution by arbitration before the
            American Arbitration Association ("AAA") in the county where you
            live by filing a Demand for Arbitration. The arbitrator will have
            exclusive authority to resolve any dispute including any claim that
            all or any part of these Terms of Website Use are unenforceable.
          </p>
          .
          <p className="text-justify">
            Opt-Out of Arbitration/Class Action Waiver. The Terms & Conditions
            do not constitute a waiver of any of your rights and remedies to
            pursue a claim individually and not as a class action in binding
            arbitration as provided above. This provision preventing you from
            bringing, joining or participating in class action lawsuits is an
            independent agreement. You may opt-out of these Dispute Resolution
            Provisions by providing written notice of your decision within
            thirty (30) days of the date that you first register on this
            service.
          </p>
          <p className="text-justify">
            YOU ACKNOWLEDGE AND AGREE THAT, VIA YOUR ACCEPTANCE OF THESE DISPUTE
            RESOLUTION PROVISIONS, YOU WAIVE ANY RIGHT TO A JURY TRIAL, AS WELL
            AS YOUR RIGHT TO BRING, JOIN OR PARTICIPATE AS A PLAINTIFF OR A
            CLASS MEMBER IN A CLASS ACTION SUIT OR MULTI-PARTY ARBITRATION
            BROUGHT AGAINST US, ANY PERSON RELATED TO US OR A SERVICE PROVIDER
            USED BY US TO PROVIDE THE SERVICE.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
