import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function RatesFees(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Rates & Fees</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            Our services are totally free to you! We do not charge any fees for
            connecting you with lenders. We do not provide loans and we do not
            have any influence over the rates and terms of our lenders.
          </p>
          <p className="text-justify">
            Since The Credit Lending is not a lender, we are not able to provide
            you with information on actual rates, fees, and APR (annual
            percentage rates) with the loan you may receive. Rates and terms of
            loans may vary by state and by lender.
          </p>
          <p className="text-justify">
            After connecting you with a lender, and the loan is approved, the
            interest rates and fees that you will incur during repayment will be
            provided to you. This is a requirement of the Truth in Lending Act.
            If you agree to the rates and fees, the lender will direct you to an
            e-signature page, and your loan will be processed.
          </p>
          <h3>Implications of Non-Payment</h3>
          <p className="text-justify">
            Some lenders may automatically roll over your existing loan for an
            additional two weeks if you don't pay back the loan on time. Fees
            for renewing the loan differ between lender. Usually these fees
            equal the fees you paid to get the initial payday loan. We insist
            that our lenders follow legal and ethical collection practices set
            by industry associations and government agencies. Please note that
            non-payment of a payday loan may adversely affect your credit
            rating.
          </p>
          <h3>Disclosure of Terms</h3>
          <p className="text-justify">
            The lender will provide you with a detailed document showing all of
            the terms of the loan. This will include the rates and fees that you
            will be responsible for. We recommended you carefully read through
            all the terms of the loan before making a final decision. If you are
            happy with the terms, you will be directed to a page requiring your
            e-signature.
          </p>
          <p className="text-justify">
            Fees, and terms are directly decided on by the lender, we are not
            responsible for any of the terms presented to you. Our Service
            simply connects you with a lender and we are not knowledgeable of
            any terms of the loan offered to you. If you are not happy with the
            loan terms please do not proceed with the lender.
          </p>
          <h3>Implications of Late Payments</h3>
          <p className="text-justify">
            Each lender in our network has their own policy when it comes to
            late payments. Before you agree to a loan you will be provided with
            the information regarding the implications of making late payments.
            Be sure that you understand the policy before you e-sign for a loan.
            Each state has rules and regulations in place that payday lenders
            must follow when assessing fees for late payments.
          </p>
          <p className="text-justify">
            Implications of Non-Payment: Some lenders may automatically roll
            over your existing loan for an additional two weeks if you don't pay
            back the loan on time. Fees for renewing the loan differ between
            lenders. Usually these fees equal the fees you paid to get the
            initial payday loan. We insist that our lenders follow legal and
            ethical collection practices set by industry associations and
            government agencies. Please note that non-payment of a payday loan
            may adversely affect your credit rating.
          </p>
          <h3>Disclosure of Renewal Policy</h3>
          <p className="text-justify">
            Extending the repayment terms of a loan may result in additional
            interest paid, as well as late fees, which can significantly
            increase the total amount you pay back on a loan. In most cases the
            loan renewal policies are regulated by the state government.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
