import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function AdvertiserDisclosure(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Advertiser Disclosure</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            THE OPERATOR OF THIS WEBSITE is a for-profit advertising network.
            Lenders, aggregators, credit repair businesses, or other offers that
            we refer you to pay us money in the form of referral fees.
            Therefore, which offer you are presented with may depend on the
            amount of compensation offered in addition to other considerations.
            The company that made the highest bid for your information may be
            the one making the offer to you. You may not always receive an offer
            from the owner of this website with the best prices or conditions.
            Not all businesses or deals are represented on our website. We urge
            you to look into all of the financing possibilities that are
            available for your circumstance.
          </p>

          <p className="text-justify">
            * The owner of this website does not decide whether to grant credit
            or cash advances, nor does she act as a loan broker for lenders.
            There is no lending offer or solicitation on this website. The data
            you give will be forwarded to a lender via this website. You could
            occasionally be given the choice to get a loan from a tribal lender.
            Tribal lenders are exempt from state law, including usury
            limitations, but are subject to specific tribal and federal
            legislation. Please be aware that if you are connected to a tribal
            lender, the rates and fees may be higher than those of
            state-licensed lenders, and you might be asked to consent to having
            any disagreements resolved in a tribal court.Your data can also be
            sent to an aggregator rather than a lender. Your data may be sold
            more than once, resulting in more than one offer from lenders,
            aggregators, and other marketers. It is not a given that you will be
            granted a cash advance if you submit your information on this
            website. The owner of this website is not a lender’s agent,
            representative, or broker, and they do not recommend or charge you
            for any good or service. Some lenders have a maximum $2,500 credit
            limit. Depending on your specific financial institution, cash
            transfer timings may differ amongst lenders.In some cases, faxing
            can be necessary. The states that are served by this website may
            vary from time to time and without prior notice, and this service is
            not available in all of them. Please get in touch with your lender
            immediately if you have any questions or issues about your cash
            advance. Cash advances are not intended to be long-term fixes, but
            rather short-term loans to address your immediate cash demands. Due
            to lender rules, residents of several states could not be eligible
            for a cash advance.
          </p>

          <p className="text-justify">
            ** Experian, Equifax, or Trans Union are the three credit reporting
            bureaus through which lenders may conduct credit checks. Some
            lenders may acquire credit checks or consumer reports from other
            vendors.
          </p>

          <p className="text-justify">
            ANTI-SPAM POLICY: We vehemently forbid any mention or promotion of
            our company name or website through unsolicited email
            communications. The relationship will be terminated for violating
            this policy, and any other legal remedies will be available. Please
            see our Privacy Policy if you believe you have received unsolicited
            messages promoting our company or website and would like to file a
            complaint. All complaints will be looked into, and any required
            action will be taken.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
