export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];
export const unsecure_debt = [
  { value: "N", label: "No" },
  { value: "Y", label: "Yes" },
];
export const credit_score = [
  { value: "excellent", label: "720+" },
  { value: "good", label: "660-719" },
  { value: "fair", label: "600-659" },
  { value: "poor", label: "less than 600" },
];
export const months = [
  { value: "1", label: "1 Month or less" },
  { value: "2", label: "2 Months" },
  { value: "3", label: "3 Months" },
  { value: "6", label: "3-6 Months" },
  { value: "12", label: "6-12 months" },
  { value: "24", label: "1-2 years" },
  { value: "60", label: "2-5 years" },
  { value: "96", label: "5-8 years" },
  { value: "108", label: "8 Years or more" },
];
export const income_type = [
  { value: "employed", label: "Employed" },
  { value: "selfEmployed", label: "Self Employed" },
  { value: "disability", label: "Disability" },
  { value: "benefits", label: "Benefits" },
  { value: "pension", label: "Pension" },
  { value: "socialSecurity", label: "Spcial Security" },
];
export const yes_no = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];
export const homeOwner = [
  { value: "own", label: "Own" },
  { value: "rent", label: "Rent" },
];
export const account_type = [
  { value: "checking", label: "Checking" },
  { value: "savings", label: "Savings" },
];
export const pay_frequency = [
  { value: "weekly", label: "Weekly" },
  { value: "biWeekly", label: "Bi-Weekly" },
  { value: "twiceMonthly", label: "Twice Monthly" },
  { value: "monthly", label: "Monthly" },
];
export const amount = [
  { value: "100", label: "$100" },
  { value: "200", label: "$200" },
  { value: "300", label: "$300" },
  { value: "400", label: "$400" },
  { value: "500", label: "$500" },
  { value: "600", label: "$600" },
  { value: "700", label: "$700" },
  { value: "800", label: "$800" },
  { value: "900", label: "$900" },
  { value: "1000", label: "$1000" },
  { value: "1500", label: "$1500" },
  { value: "2000", label: "$2000" },
  { value: "2500", label: "$2500" },
];

export const purpose = [
  { value: "boat", label: "Boat" },
  { value: "business", label: "Business" },
  { value: "debtConsolidation", label: "Debt Consolidation" },
  { value: "education", label: "Education" },
  { value: "greenEnergy", label: "Green Energy" },
  { value: "holiday", label: "Holiday" },
  { value: "homeImprovement", label: "Home Improvement" },
  { value: "householdExpenses", label: "House Hold Expenses" },
  { value: "medical", label: "medical" },
  { value: "movingHouse", label: "Moving House" },
  { value: "oneoffPurchase", label: "One Off Purchase" },
  { value: "billTaxes", label: "Bill Taxes" },
  { value: "vehicle", label: "Vehicle" },
  { value: "wedding", label: "Wedding" },
  { value: "other", label: "Other" },
];
