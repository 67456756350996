import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-dropdown-select"; // https://react-dropdown-select.netlify.app/
import { DatePicker } from "antd";
import {
  months,
  income_type,
  pay_frequency,
  yes_no,
  purpose,
} from "../library/local_data";
import ErrorLabel from "../Component/ErrorLabel";
import Joi from "joi";
import moment from "moment";
export default function Step2(props) {
  const { setRequest, error, blurAction, request } = props;
  return (
    <>
      <div className="row mb-3 mt-3">
        <div className="col">
          <h4 className="lf-block-heading">Step 2</h4>
          <label>
            Please provide a little information about your employment.
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Income Type
              <ErrorLabel error={error} id="incomeSource" />
            </Form.Label>
            <Select
              className={error["incomeSource"] ? "val_error" : ""}
              searchable={false}
              options={income_type}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["incomeSource"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "incomeSource" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Months Employed <ErrorLabel error={error} id="timeJob" />
            </Form.Label>
            <Select
              className={error["timeJob"] ? "val_error" : ""}
              searchable={false}
              options={months}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["timeJob"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "timeJob" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Employer Name <ErrorLabel error={error} id="company" />
            </Form.Label>
            <Form.Control
              className={error["company"] ? "val_error" : ""}
              // placeholder="Address"
              aria-label="Employer Name"
              onChange={(e) => {
                setRequest((request) => {
                  request["company"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "company" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Employer Phone <ErrorLabel error={error} id="workPhone" />
            </Form.Label>
            <Form.Control
              className={error["workPhone"] ? "val_error" : ""}
              // placeholder="City"
              type="number"
              aria-label="Employer Phone"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setRequest((request) => {
                    request["workPhone"] = e.target.value;
                    return request;
                  });
                } else e.target.value = request["workPhone"];
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().length(10).trim().required(),
                });
                blurAction({ name: "workPhone", schema });
              }}
            />
            <Form.Text className="text-muted">
              Your employer will not be contacted. This information may be used
              to help determine your loan amount.
            </Form.Text>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Job Title <ErrorLabel error={error} id="jobTitle" />
            </Form.Label>
            <Form.Control
              className={error["jobTitle"] ? "val_error" : ""}
              // placeholder="Job Title"
              aria-label="Job Title"
              onChange={(e) => {
                setRequest((request) => {
                  request["jobTitle"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "jobTitle" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Monthly Income <ErrorLabel error={error} id="income" />
            </Form.Label>
            <Form.Control
              className={error["income"] ? "val_error" : ""}
              // placeholder="Monthly Income"
              type="number"
              aria-label="Monthly Income"
              onChange={(e) => {
                setRequest((request) => {
                  request["income"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "income" });
              }}
            />
            {/* <Form.Text className="text-muted">
              List your total available income including wages, retirement,
              investments, and rental properties. You are not required to
              disclose income that is alimony, child support or separate
              maintenance unless you want to use that income to qualify for a
              loan. If applicable, include self-employment salary. For total
              commission earnings, provide an annual average. Increase
              non-taxable income/benefits by 25%.
            </Form.Text> */}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Paid By Direct Deposit <ErrorLabel error={error} id="income" />
            </Form.Label>
            <Select
              className={error["directDeposit"] ? "val_error" : ""}
              searchable={false}
              options={yes_no}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["directDeposit"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "directDeposit" });
              }}
            />
            {/* <Form.Text className="text-muted">
              I don't have a checking account. To search sponsored options click
              here
            </Form.Text> */}
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Pay Frequency <ErrorLabel error={error} id="frequency" />
            </Form.Label>
            <Select
              className={error["frequency"] ? "val_error" : ""}
              searchable={false}
              options={pay_frequency}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["frequency"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "frequency" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Pay Date <ErrorLabel error={error} id="payDate" />
            </Form.Label>
            <DatePicker
              disabledDate={(current) => {
                return (
                  moment().add(-1, "days") >= current ||
                  moment().add(1, "month") <= current
                );
              }}
              inputReadOnly={true}
              className={`date-picker ${error["military"] ? "val_error" : ""}`}
              onChange={(date, dateString) => {
                // console.log(date, dateString);
                setRequest((request) => {
                  request["payDate"] = dateString;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "payDate" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-3 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Next Pay Date <ErrorLabel error={error} id="payDateNext" />
            </Form.Label>
            <DatePicker
              disabledDate={(current) => {
                return (
                  moment().add(-1, "days") >= current ||
                  moment().add(1, "month") <= current
                );
              }}
              inputReadOnly={true}
              className={`date-picker ${error["military"] ? "val_error" : ""}`}
              onChange={(date, dateString) => {
                // console.log(date, dateString);
                setRequest((request) => {
                  request["payDateNext"] = dateString;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "payDateNext" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-3 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Active in Military or Dependent
              <ErrorLabel error={error} id="military" />
            </Form.Label>
            <Select
              className={error["military"] ? "val_error" : ""}
              searchable={false}
              options={yes_no}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["military"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "military" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-3 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Purpose
              <ErrorLabel error={error} id="purpose" />
            </Form.Label>
            <Select
              className={error["purpose"] ? "val_error" : ""}
              searchable={false}
              options={purpose}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["purpose"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "purpose" });
              }}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
}
