import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-dropdown-select"; // https://react-dropdown-select.netlify.app/
import {
  unsecure_debt,
  credit_score,
  months,
  account_type,
} from "../library/local_data";
import ErrorLabel from "../Component/ErrorLabel";
import Joi from "joi";

export default function Step3(props) {
  const { setRequest, error, blurAction, request } = props;

  return (
    <>
      <div className="row mb-3 mt-3">
        <div className="col">
          <h4 className="lf-block-heading">Step 3</h4>
          <label>Where would you like your cash deposited?</label>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Account Type <ErrorLabel error={error} id="accountType" />
            </Form.Label>
            <Select
              className={error["accountType"] ? "val_error" : ""}
              searchable={false}
              options={account_type}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["accountType"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "accountType" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-4 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Routing Number
              <ErrorLabel error={error} id="accountABA" />
            </Form.Label>
            <Form.Control
              className={error["accountABA"] ? "val_error" : ""}
              // placeholder="Address"
              type="number"
              aria-label="Routing Number"
              onChange={(e) => {
                if (e.target.value.length <= 9) {
                  setRequest((request) => {
                    request["accountABA"] = e.target.value;
                    return request;
                  });
                } else e.target.value = request["accountABA"];
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().length(9).trim().required(),
                });
                blurAction({ name: "accountABA", schema });
              }}
            />
            <Form.Text className="text-muted">
              Your bank's Routing Number can be found at the bottom left of your
              checks.
            </Form.Text>
          </Form.Group>
        </div>
        <div className="col-lg-4 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Account Number
              <ErrorLabel error={error} id="accountNumber" />
            </Form.Label>
            <Form.Control
              className={error["accountNumber"] ? "val_error" : ""}
              // placeholder="Address"
              type="number"
              aria-label="Account Number"
              onChange={(e) => {
                setRequest((request) => {
                  request["accountNumber"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().min(4).trim().required(),
                });
                blurAction({ name: "accountNumber", schema });
              }}
            />
            <Form.Text className="text-muted">
              Your Account Number is the second number from the left, on the
              bottom of your checks.
            </Form.Text>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Bank Name
              <ErrorLabel error={error} id="bankName" />
            </Form.Label>
            <Form.Control
              className={error["bankName"] ? "val_error" : ""}
              // placeholder="Address"
              aria-label="Bank Name"
              onChange={(e) => {
                setRequest((request) => {
                  request["bankName"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "bankName" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Time at Bank
              <ErrorLabel error={error} id="accountLength" />
            </Form.Label>
            <Select
              className={error["accountLength"] ? "val_error" : ""}
              searchable={false}
              options={months}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["accountLength"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "accountLength" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Credit Score
              <ErrorLabel error={error} id="creditScore" />
            </Form.Label>
            <Select
              className={error["creditScore"] ? "val_error" : ""}
              searchable={false}
              options={credit_score}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["creditScore"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "creditScore" });
              }}
            />
            {/* <Form.Text className="text-muted">
              Please estimate your credit score. Don't worry, all credit types
              are considered
            </Form.Text> */}
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Bankruptcy in the last 7 years
              <ErrorLabel error={error} id="bankruptcy" />
            </Form.Label>
            <Select
              className={error["bankruptcy"] ? "val_error" : ""}
              searchable={false}
              options={unsecure_debt}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["bankruptcy"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "bankruptcy" });
              }}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
}
