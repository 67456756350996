import React from "react";

export default function HomePagePart() {
  return (
    <>
      <div className="row">
        <div className="col  pt-5 pb-1">
          <h2 style={{ textAlign: "center" }}>Get Your Loan in 3 Easy Steps</h2>
          <p style={{ textAlign: "center" }}>
            Simplified, so you can get on your way. Leading security technology,
            for your peace of mind.
          </p>
        </div>
      </div>
      <div className="row justify-content-center p-5">
        <div className="col-lg-3 col-12 text-center">
          <img src="/easyloan.png" />
          <p style={{ textAlign: "center" }}>
            Easy Loan Request
            <br />
            Fill out our short, secure online loan request and submit.
          </p>
        </div>
        <div className="col-1 d-lg-block d-none  pt-5 text-center">
          <svg
            style={{ width: "0.875em" }}
            role="img"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            className="svg-inline--fa fa-arrow-right fa-w-14"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            ></path>
          </svg>
        </div>
        <div className="col d-lg-none d-block  text-center">
          <svg
            style={{ width: "0.875em" }}
            role="img"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            className="svg-inline--fa fa-arrow-right fa-w-14"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
            ></path>
          </svg>
        </div>
        <div className="col-lg-3 col-12 text-center">
          <img src="/fastApproval.png" />
          <p style={{ textAlign: "center" }}>
            Fast Approval
            <br />
            Get approved in as little as 2 minutes.
          </p>
        </div>
        <div className="col-1 d-lg-block d-none   pt-5 text-center">
          <svg
            style={{ width: "0.875em" }}
            role="img"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            className="svg-inline--fa fa-arrow-right fa-w-14"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            ></path>
          </svg>
        </div>
        <div className="col d-lg-none d-block  text-center">
          <svg
            style={{ width: "0.875em" }}
            role="img"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-right"
            className="svg-inline--fa fa-arrow-right fa-w-14"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z"
            ></path>
          </svg>
        </div>
        <div className="col-lg-3 col-12 text-center">
          <img src="/cash.png" />
          <p style={{ textAlign: "center" }}>
            Receive Your Funds
            <br />
            Your money directly into your account in as little as one business
            day. Why wait?
          </p>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col text-center">
          <a
            href="#short-form"
            className="p-3 submit-button "
            // style={{
            //   borderRadius: "10px",
            //   boxShadow: "0px 0px 5px #81942c",
            //   width: "190px",
            //   height: "50px",
            //   background: "#81942c",
            //   color: "#fff",
            //   textDecoration: "none",
            // }}
          >
            START NOW
          </a>
        </div>
      </div>
      <div
        className="row justify-content-center p-5 "
        style={{ background: "#eff3f6" }}
      >
        <div className="col-lg-6 col-12">
          <h2 className="text-center">Why The Credit Lending Is Beneficial</h2>
          <p className="text-justify">
            For any reason, The Credit Lending was created to make it easy for
            you to apply for the loan you require! A personal loan is money you
            borrow for your own use, such as for emergency auto repairs, home
            remodeling tasks, vacations, assistance with wedding costs, and any
            other pressing needs. So don’t wait. Fill out our form to request
            the loan you’ve been searching for with The Credit Lending.
          </p>
          {/* <p>
            Our process is safe, secure, fast and reliable. No waiting in lines
            or going to a physical store. Request your loan straight from your
            mobile phone, or computer. You may be approved in minutes, and your
            cash will be deposited straight into your bank account.
          </p>
          <p>
            So don't wait. Fill out our form to request the loan you've been
            searching for with The Credit Lending.
          </p> */}
        </div>
      </div>
    </>
  );
}
