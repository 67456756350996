import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHeading from "./FormHeading";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { myAxios } from "../library/networkFunction";
import { longform_api } from "../library/form_api";
import ErrorToolTip from "../Component/ErrorToolTip";
import { zip } from "../library/zip";
import _ from "lodash";
import Joi from "joi";
export default function LongForm(props) {
  const { short_id, setData } = props;
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const subAff = params.get("subaff");
  const sid = params.get("sid");
  const [request, setRequest] = useState({ short_id, subAff, sid });
  const [error, setError] = useState({});
  const blurAction = ({ value, name, schema }) => {
    if (!value) value = request[name];
    if (!schema)
      schema = Joi.object({
        value: Joi.string().trim().required(),
      });
    const { error: error1 } = schema.validate({ value });
    if (error1) setError({ ...error, [name]: true });
    else setError({ ...error, [name]: false });
  };
  const submitLong = async () => {
    const schema = Joi.object({
      short_id: Joi.string().trim(),
      firstName: Joi.string().trim().required(),
      lastName: Joi.string().trim().required(),
      streetAddress: Joi.string().trim().required(),
      locality: Joi.string().trim().required(),
      region: Joi.string().trim().required(),
      postalCode: Joi.string().length(5).trim().required(),
      homeOwner: Joi.string().trim().required(),
      timeAddress: Joi.string().trim().required(),
      driverLicense: Joi.string().trim().required(),
      driverState: Joi.string().trim().required(),
      dob: Joi.string().trim().required(),
      SSN: Joi.string().length(9).trim().required(),
      mobilePhone: Joi.string().length(10).trim().required(),
      incomeSource: Joi.string().trim().required(),
      timeJob: Joi.string().trim().required(),
      company: Joi.string().trim().required(),
      workPhone: Joi.string().length(10).trim().required(),
      jobTitle: Joi.string().trim().required(),
      income: Joi.string().trim().required(),
      directDeposit: Joi.string().trim().required(),
      frequency: Joi.string().trim().required(),
      payDateNext: Joi.string().trim().required(),
      payDate: Joi.string().trim().required(),
      purpose: Joi.string().trim().required(),
      military: Joi.string().trim().required(),
      accountType: Joi.string().trim().required(),
      accountABA: Joi.string().length(9).trim().required(),
      accountNumber: Joi.string().trim().required(),
      bankName: Joi.string().trim().required(),
      accountLength: Joi.string().trim().required(),
      creditScore: Joi.string().trim().required(),
      bankruptcy: Joi.string().trim().required(),
      agree: Joi.boolean().invalid(false).required(),
      subAff: Joi.string().allow(null, ""),
      sid: Joi.string().allow(null, ""),
    });
    const obj = schema.validate(request, { abortEarly: false });
    let { error, value } = obj;
    const { postalCode } = value;
    if (error) {
      const { details } = error;
      const invalid = details.map(({ path }) => path[0]);
      const invalid_field = {};
      invalid.forEach((key) => {
        invalid_field[key] = true;
      });
      setError(invalid_field);
      alert("Please fill all required field");
      return false;
    }
    const validZip = zip.some((zip) => zip == postalCode);
    if (!validZip) {
      setError({ postalCode: true });
      return false;
    }
    // // Block list
    // // Remove the values you don't want
    const final_request = _.omit(request, ["agree"]);
    // // Allow list
    // // Only allow certain values
    // var result = _.pick(credentials, ["fname", "lname"]);
    const url = `${longform_api}/`;
    const response = await myAxios({
      method: "post",
      url,
      request: final_request,
    });
    const { redirectUrl, errors } = response.data;
    if (redirectUrl) window.location = redirectUrl["_cdata"];
    else {
      const invalid_field = {};
      Object.keys(errors).map((key) => {
        invalid_field[key] = errors[key]["_text"];
      });
      setError(invalid_field);
      alert("please check form values");
    }
  };
  return (
    <>
      <FormHeading
        main="Almost Done!"
        sub="Complete the form to request your loan."
      />
      <div className="row justify-content-center">
        <div className="col-10 m-5 p-5 pt-3 pb-3 bg-light rounded">
          <Step1
            error={error}
            request={request}
            setRequest={setRequest}
            blurAction={blurAction}
          />
          <Step2
            error={error}
            request={request}
            setRequest={setRequest}
            blurAction={blurAction}
          />
          <Step3
            error={error}
            request={request}
            setRequest={setRequest}
            blurAction={blurAction}
          />
          <div className="row mt-3 mb-3">
            <div className="col text-left">
              <ErrorToolTip
                id="agree"
                error={error}
                title="You must accept the terms and conditions to continue."
              >
                <Form.Check
                  type={"checkbox"}
                  label={
                    <p style={{ fontSize: ".85em" }}>
                      You agree to receive calls, autodialed or artificial
                      voices and pre recorded marketing text messages from
                      marketing partners, lenders, subsidiaries, and/or agents.
                      Consent is not required to purchase goods or services.
                      Message and data rates may apply. Receive recurring
                      monthly messages. Privacy Policy, and Terms & Conditions,
                      and E-Consent To Submit form without providing consent to
                      be contacted, click here.
                    </p>
                  }
                  onClick={(e) => {
                    const value = e.target.checked;
                    setRequest((request) => {
                      request["agree"] = value;
                      return request;
                    });
                    const schema = Joi.object({
                      value: Joi.boolean().invalid(false),
                    });
                    blurAction({ name: "agree", schema, value });
                  }}
                />
              </ErrorToolTip>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col text-center">
              <Button
                variant="primary"
                type="submit"
                onClick={submitLong}
                className="mb-3 submit-button"
              >
                Submit
              </Button>
              <br />
              <span style={{ fontSize: ".85em" }}>
                We use 256-bit SSL technology to encrypt your data
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
