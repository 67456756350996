import Axios from "axios";
// import Cookies from "js-cookie";
// =============================================
// =============================================
function check_status({ status }) {
  // 200 success
  // 404 not found
  // 403 access denied
  // 400 missing info
  // switch (status) {
  //   case 200:
  //     console.log("status is 200 ");
  //     break;
  //   case 400:
  //     console.log("status is 400 ");
  //     break;
  //   case 403:
  //     console.log("status is 403 ");
  //     break;
  //   case 404:
  //     console.log("status is 404 ");
  //     break;
  // }
}
// =============================================
// =============================================
function create_options({ header, blob }) {
  let options = {
    // withCredentials: true,
    metadata: { startTime: new Date().getTime() },
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
    },
  };
  if (blob) options["responseType"] = "blob";
  if (header && header.Chabi) options.headers["token"] = header.Chabi;
  // const Chabi = Cookies.get("Chabi");
  // if (Chabi) options.headers["Chabi"] = Chabi;
  return options;
}
// =============================================
// =============================================
export const myAxios = async (obj) => {
  const { url, method, request, header } = obj;
  let options = create_options({ header });
  let response;
  try {
    switch (method) {
      case "get":
        response = await Axios.get(url, options);
        break;
      case "post":
        response = await Axios.post(url, request, options);
        break;
      case "put":
        response = await Axios.put(url, request, options);
        break;
      case "delete":
        response = await Axios.delete(url, { ...options, data: request });
        // response = await Axios.delete(url, { data: request }, options);
        break;
      default:
        return { error: "method not found" };
    }
    check_status(response);
    // response.config.metadata["endTime"] = new Date().getTime();
    return response;
  } catch (error) {
    //     // let keys = Object.keys(error);
    //     // keys.map((key) => {
    //     //   console.log(error[key]);
    //     // });
    let response = error.response;
    let message = error.message;
    console.log("error ", error);
    return { messages: "HTTP Error" };
  }
};
// ============================================
// ============================================
