import React from "react";

import FormHeading from "./FormHeading";

export default function Thanks() {
  return (
    <>
      <FormHeading
        main="Thank you"
        // sub="Complete the form to request your loan."
      />
      <div className="row justify-content-center">
        <div
          className="col-10 p-5 pt-3 pb-3 bg-light rounded text-center"
          style={{ fontSize: "36px" }}
        >
          Your details submitted successfully
        </div>
      </div>
    </>
  );
}
