import React from "react";
import { Tooltip } from "antd";
export default function ErrorToolTip(props) {
  const { children, id, error, title, placement } = props;
  return (
    <Tooltip
      //   key={error[id]}
      title={title ? title : "This field is required"}
      open={error && error[id] ? true : false}
      placement={placement ? placement : "left"}
      defaultOpen={error && error[id] ? true : false}
      color="#f00"
    >
      {children}
    </Tooltip>
  );
}
