import React, { useState } from "react";
import Select from "react-dropdown-select"; // https://react-dropdown-select.netlify.app/
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormHeading from "./FormHeading";
import { myAxios } from "../library/networkFunction";
import { shortform_api } from "../library/form_api";
import _ from "lodash";
import { amount } from "../library/local_data";
import Joi from "joi";
import ErrorToolTip from "../Component/ErrorToolTip";
import ErrorLabel from "../Component/ErrorLabel";
import { zip } from "../library/zip";
export default function ShortForm(props) {
  const {
    setData,
    set_econsent,
    set_advertiser_disclosure,
    set_privacy_policy,
    set_terms_conditions,
  } = props;
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const subAff = params.get("subaff");
  const sid = params.get("sid");
  const [request, setRequest] = useState({ amount: "500", subAff, sid });
  const [error, setError] = useState({});

  const blurAction = ({ value, name, schema }) => {
    if (!value) value = request[name];
    if (!schema)
      schema = Joi.object({
        value: Joi.string().trim().required(),
      });
    const { error: error1 } = schema.validate({ value });
    if (error1) setError({ ...error, [name]: true });
    else setError({ ...error, [name]: false });
  };
  const submitShort = async () => {
    const schema = Joi.object({
      amount: Joi.string().trim().required(),
      email: Joi.string()
        .trim()
        .required()
        .email({ tlds: { allow: false } }),
      postalCode: Joi.string().length(5).trim().required(),
      subAff: Joi.string().allow(null, ""),
      sid: Joi.string().allow(null, ""),
      agree: Joi.boolean().invalid(false).required(),
    });
    const obj = schema.validate(request, { abortEarly: false });
    let { error, value } = obj;
    const { postalCode } = value;
    if (error) {
      const { details } = error;
      console.log("details ", details);
      const invalid = details.map(({ path }) => path[0]);
      const invalid_field = {};
      invalid.forEach((key) => {
        invalid_field[key] = true;
      });
      setError(invalid_field);
      return false;
    }
    const validZip = zip.some((zip) => zip == postalCode);
    if (!validZip) {
      setError({ postalCode: true });
      return false;
    }
    // // Block list
    // // Remove the values you don't want
    const final_request = _.omit(request, ["agree"]);
    // // Allow list
    // // Only allow certain values
    // var result = _.pick(credentials, ["fname", "lname"]);
    const url = `${shortform_api}/`;
    const response = await myAxios({
      method: "post",
      url,
      request: final_request,
    });
    const { ref, status } = response.data;
    if (status == 200) setData(ref);
    else alert("please try again");
  };
  return (
    <>
      <FormHeading main="Could you use a loan up to $2,500?" />
      <div
        id="short-form"
        className="row justify-content-center"
        style={{
          backgroundImage: "url(./banner.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="col-lg-4 col-10 m-5 p-5 pt-3 pb-3 bg-light rounded">
          <h2 className="text-center">How much do you want?</h2>
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Amount
              <ErrorLabel error={error} id="amount" />
            </Form.Label>
            <Select
              className={error["amount"] ? "val_error" : ""}
              searchable={false}
              options={amount}
              labelField="label"
              valueField="value"
              values={[{ value: "500", label: "$500" }]}
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["amount"] = values[0]["value"];
                    return request;
                  });
                }
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Email <ErrorLabel error={error} id="email" />
            </Form.Label>
            <Form.Control
              defaultValue={request["email"]}
              className={error["email"] ? "val_error" : ""}
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setRequest((request) => {
                  request["email"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string()
                    .trim()
                    .required()
                    .email({ tlds: { allow: false } }),
                });
                blurAction({ name: "email", schema });
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">Zip Code</Form.Label>
            <ErrorLabel
              error={error}
              id="postalCode"
              message="Invalid Zip Code"
            />
            <Form.Control
              className={error["postalCode"] ? "val_error" : ""}
              placeholder="Zip Code"
              aria-label="Zip Code"
              type="number"
              aria-describedby="basic-addon1"
              defaultValue={request["postalCode"]}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 5) {
                  setRequest((request) => {
                    request["postalCode"] = value;
                    return request;
                  });
                } else e.target.value = request["postalCode"];
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().length(5).trim().required(),
                });
                blurAction({ name: "postalCode", schema });
              }}
            />
          </Form.Group>
          <span>
            <ErrorToolTip
              id="agree"
              error={error}
              title="You must accept the terms and conditions to continue."
            >
              <Form.Check
                type={"checkbox"}
                label={
                  <p style={{ fontSize: ".85em" }}>
                    I am a U.S. Resident over the age of 18, I agree to the
                    <a
                      className="click-link"
                      onClick={() => {
                        set_econsent(true);
                      }}
                    >
                      E-Consent
                    </a>
                    ,
                    <a
                      className="click-link"
                      onClick={() => {
                        set_advertiser_disclosure(true);
                      }}
                    >
                      Advertising Disclosure
                    </a>
                    ,
                    <a
                      className="click-link"
                      onClick={() => {
                        set_privacy_policy(true);
                      }}
                    >
                      Privacy Policy
                    </a>
                    and
                    <a
                      className="click-link"
                      onClick={() => {
                        set_terms_conditions(true);
                      }}
                    >
                      Terms & Conditions
                    </a>
                    and I may receive special offers.
                  </p>
                }
                onClick={(e) => {
                  const value = e.target.checked;
                  setRequest((request) => {
                    request["agree"] = value;
                    return request;
                  });

                  const schema = Joi.object({
                    value: Joi.boolean().invalid(false),
                  });
                  blurAction({ name: "agree", schema, value });
                }}
              />
            </ErrorToolTip>
          </span>
          <div className="row mt-3 mb-3">
            <div className="col text-center">
              <Button
                // variant="primary"
                type="submit"
                onClick={submitShort}
                className="mb-3 submit-button"
                // style={{
                //   borderRadius: "10px",
                //   boxShadow: "0px 0px 5px #81942c",
                //   width: "190px",
                //   height: "50px",
                //   background: "#81942c",
                //   color: "#fff",
                //   textDecoration: "none",
                //   border: "none",
                // }}
              >
                Get Started
              </Button>
              <br />
              <span style={{ fontSize: ".85em" }}>
                We use 256-bit SSL technology to encrypt your data
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
