import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function PrivacyPolicy(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>We appreciate your reference to the Privacy Policy.</h2>

          <p className="text-justify">
            You are subject to this privacy statement if you use the loan
            connection service or the The Credit Lending ® website
            (collectively, "Service").
          </p>

          <p className="text-justify">
            Operating this Service is The Credit Lending ®. (hereinafter
            "Company"). The use of the information collected by the Company and
            the protection of the personal information that You submit to this
            Service are both governed by this Privacy Policy, which is an
            agreement between the Company and You (hereinafter "You" and/or
            "Your") as the user of this Service and the products and services of
            the Company.
          </p>

          <h5>Compilation of Information</h5>
          <p className="text-justify">
            Both "personally identifiable information" and "non-personally
            identifiable information" are gathered from You by this Service.
          </p>

          <h3>Personally Identifiable Information</h3>
          <p className="text-justify">
            We collect numerous items of information that may identify a
            particular individual ("personally identifiable information"). This
            information is collected when You provide it to us through your use
            of the Service, for example when completing one of the stages of the
            form before being introduced to prospective lender. The types of
            personally identifiable information we may collect include: name,
            Social Security number, driver's license and other identification
            numbers, citizenship, birth date, home and personal cell telephone
            numbers, personal email address, mailing and home address, financial
            information, and employment information. This personally
            identifiable information allows the Company to deliver products or
            services to You as well as providing customer support. It also
            allows the Company to collect the information required by lenders to
            review your form data to establish if You meet suitability
            requirements for these participating lenders. This personally
            identifiable information allows the Company to communicate with You
            about products or services that may be of interest to you either
            from us, our marketing partners, or other third parties. This
            communication may be via email, mobile devices, postal mail or
            telephone, which includes telemarketing calls, automatic telephone
            dialling system or an artificial or pre-recorded voice message. We
            may review and use your financial information and Your social
            security number to connect you with participating lenders in our
            loan network or other third party lenders with whom we have a
            business relationship. We may also review Your financial information
            and social security number to verify Your identify and protect
            against possible fraudulent transaction.
          </p>

          <h3>Non-Personally Identifiable Information</h3>
          <p className="text-justify">
            Anonymous information that cannot be associated with a specific
            individual is classified as Non-personally identifiable information.
            This data can be collected using "cookies" or via third party
            tracking systems. The collected information includes: the IP
            address, browser type, access times, information about areas of the
            Service visited by users of the Service and clicked links used
            during the site visit. IP addresses are not linked to personally
            identifiable information. This Service uses Cookies to enhance your
            user experience. Cookies are data sent to your computer when you
            visit a website. They allow this Service to recognize your browser
            and store your preferences. Cookies do not provide the Company with
            Personally Identifiable Information. Cookies can be disabled via
            Your Internet browser settings. However, if you disable Cookies, you
            may not be able to use certain features of this Service or Company's
            services. The Company may share summary non-personally identifiable
            information with its suppliers and other third parties for the
            purpose of web site statistics. This information is not linked to
            any personally identifiable information.
          </p>

          <h3>Sharing Information</h3>
          <p className="text-justify">
            The Company reserves the right to share any and all information
            collected from You on this Service: (i) where required by law,
            warrant, subpoena, or legal process or requested by a court of
            competent jurisdiction, governmental authority, or
            quasi-governmental authority; (ii) in the event of Company's
            bankruptcy, or if Company is sold or acquired; (iii) where You are
            in violation of this Privacy Policy or the Terms & Conditions of
            Use.; (iv) to prevent, address, or remedy fraud, security, or
            technical issues, which necessarily may include the exchange of such
            information with trusted third parties for fraud prevention
            purposes; (v) to improve the design and content of this Service; or,
            (vi) where Company determines such disclosure is necessary to
            protect the rights of the Company and/or a third party, or to shield
            the Company from liability of any kind. You agree that by submitting
            Your information on this Service to register for the Company's
            services, You authorize the Company, other third parties and
            affiliates to contact you via email, mobile device or telephone to
            provide information regarding promotions, offers, products, or
            services in which You might be interested (notwithstanding that Your
            contact information may be listed on a corporate, state, or federal
            Do-Not-Contact registry or the like). You understand and agree that
            by willingly requesting the Company, other third parties and
            affiliates to contact you via email, mobile device or telephone you
            thereby waive your right to file a claim or complaint with the FTC,
            FCC, or any other federal or state agency with regard to such
            contact. You understand and authorize the Company to share any and
            all information collected from and/or submitted by You on this
            Service with interested third parties and affiliates in accordance
            with all applicable laws and as described elsewhere herein.
          </p>

          <h3>Information Security</h3>
          <p className="text-justify">
            We take appropriate security measures to safeguard your personal
            information from unauthorized access and disclosure. For example,
            the Company has managerial security measures in place where access
            to and use of Your personal information is limited. We use a
            reputable security service provider to manage our servers on which
            your personal information is stored. In addition, personal
            information you submit to this Service is transmitted through an
            encrypted SSL (secure socket layer technology) connection, and our
            computers and networks have security measures, such as firewalls, to
            protect against the loss, misuse and/or alteration of the
            information under the Company's control. We take all reasonable
            precautions to protect you data, however, no system can be
            completely secure. Therefore, although we take steps to secure your
            information, we do not promise, and you should not expect, that your
            personal information, searches or other communications will always
            remain secure. Users should also take care how they handle and
            disclose their personal information. Please refer to the Federal
            Trade Commission's Consumer Information website
            (http://www.consumer.ftc.gov) for information about how to protect
            yourself against identity theft.
          </p>
          <h3>Access to your information</h3>
          <p className="text-justify">
            If you have submitted a form to us, you can correct or delete
            inaccuracies of Your personal information by contacting us via our
            contact page for assistance. The Company retains the information
            collected by and through Your access to this Service as necessary to
            perform the Company's services and to comply with the Company's
            legal obligations and to enforce the Company's agreements.
          </p>
          <h3>Third Party Links</h3>
          <p className="text-justify">
            We may automatically redirect or offer links to third party web
            sites that do not operate under this Policy. You should evaluate the
            privacy policy and terms of use of any third party web sites you
            access. These third party web sites may independently collect data
            from you. Occasionally, those third party web sites may provide us
            with information about how You operated on their sites.
          </p>
          <h3>Children's Privacy</h3>
          <p className="text-justify">
            This Service is not designed for children and we do not knowingly
            collect personally identifiable information from children under the
            age of 13.
          </p>
          <h3>California Residents</h3>
          <h6>Your Rights Under the CCPA</h6>
          <p className="text-justify">
            This Privacy Notice for California Residents applies solely to all
            visitors, users, and others who reside in the State of California.
          </p>
          <p className="text-justify">
            The California Consumer Privacy Act ("CCPA") grants State of
            California residents the following rights to:
          </p>

          <h6>The Categories of Personal Information the Company Collects</h6>
          <p className="text-justify">
            Loan Amount, Email Address, First and Last Name, Telephone Number,
            Full Birth Date, Military Affiliation, Social Security number,
            Driver's License, Driver's license state, Internet Protocol (IP)
            Address, Home Phone Number, Loan Purpose, Home Owner, Full Home
            Address, Own/rent home (Residence Status), Time In Residence,
            Monthly Mortgage or Rent Payments, Available Co-signer, Income
            Source, Job Title, Employer Name, Work Phone Number, Time with
            Employer, Monthly Income, Pay Period and Paycheck Type, Bank Account
            Type, Routing Number, Bank Account Number, Bank Name, Time with
            Bank, Credit Score, Declaration of Bankruptcy, Cookies, Domain name,
            Browser Type and Version, Operating System, Usage Data, Visit
            Frequency, Referring Affiliate Information, Referring Ad
            information, Time on Site, Source URL, Redirect URL.
          </p>

          <h6>
            The Business Purpose For Which The Information Is Used By The
            Company
          </h6>
          <p className="text-justify">
            Regarding any personal information we may sell or disclose to a
            third party for a business purpose, you have the right to know:
          </p>
          <p className="text-justify">
            The categories of personal information about you that the company
            sold, the categories of third parties to whom the personal
            information was sold and the categories of personal information that
            we disclosed about you for a business purpose.
          </p>
          <p className="text-justify">
            The company will share information with Service Providers such as
            lenders and aggregators to fulfill the loan request process. The
            company may also share information with other financial service
            providers, debt relief providers, and other marketers whose services
            we believe may be of interest to you. To opt-out of this sharing,
            see how to submit an opt-out request below.
          </p>

          <h6>Know Whether Your Personal Information is Being Collected</h6>
          <p className="text-justify">
            You have the right to know what personal information is being
            collected including:
          </p>
          <ul>
            <li>
              The categories of sources from which the personal information is
              collected;
            </li>
            <li>
              The business purpose for collecting or selling personal
              information;
            </li>
            <li>
              The categories of third parties with whom the company share
              personal information, if any
            </li>
          </ul>

          <p className="text-justify">
            You have the right to request the specific categories of Information
            the company collects upon verifiable request.
          </p>
          <p className="text-justify">
            To request this information please submit a verifiable consumer
            request to us by either:
            <br />
            Contacting us on our website at the Contact Us page.
            <br />
            Please note that we are not required to:
            <br />
          </p>
          <ul>
            <li>
              Retain any personal information about you that was collected for a
              single one-time transaction if, in the ordinary course of
              business, that information about you is not retained;
            </li>
            <li>
              Reidentify or otherwise link any data that, in the ordinary course
              of business, is not maintained in a manner that would be
              considered personal information;
            </li>
            <li>
              Provide the personal information to you more than twice in a
              12-month period.
            </li>
          </ul>

          <h6>Say No to The Sale of Personal Information</h6>
          <p className="text-justify">
            You have the right under the California Consumer Privacy Act of 2018
            (CCPA) and certain other privacy and data protection laws, as
            applicable, to opt-out of the sale or disclosure of your personal
            information. If you exercise your right to opt-out of the sale or
            disclosure of your personal information, we will refrain from
            selling your personal information, unless you subsequently provide
            express authorization for the sale of your personal information. To
            opt-out of the sale [or disclosure] of your personal information,
            click on the Do Not Sell My Personal Information link.
          </p>

          <h6>Deletion of Your Personal Information</h6>
          <p className="text-justify">
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            consumer request, we will:
          </p>
          <ul>
            <li>Delete your personal information from our records; and</li>
            <li>
              Direct any service providers to delete your personal information
              from their records.
            </li>
          </ul>
          <p className="text-justify">
            Please note that we may not delete your personal information if it
            is necessary to:
          </p>
          <ul>
            <li>
              Complete the transaction for which the personal information was
              collected, fulfill the terms of a written warranty or product
              recall conducted in accordance with federal law, provide a good or
              service requested by you, or reasonably anticipated within the
              context of our ongoing business relationship with you, or
              otherwise perform a contract between you and us;
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity; or prosecute those responsible
              for that activity;
            </li>
            <li>
              Debug to identify and repair errors that impair existing intended
              functionality;
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act;
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with your
              expectations based on your relationship with us;
            </li>
            <li>Comply with an existing legal obligation; or</li>
            <li>
              Otherwise use your personal information, internally, in a lawful
              manner that is compatible with the context in which you provided
              the information.
            </li>
          </ul>

          <p className="text-justify">
            To request this information please submit a verifiable consumer
            request to us by either:
          </p>

          <p className="text-justify">
            Contacting us on our website at the Contact Us page.{" "}
          </p>

          <h6>Non-Discrimination</h6>
          <p className="text-justify">
            The Company will not discriminate against you for exercising any of
            your CCPA rights. Unless permitted by the CCPA, we will not:
          </p>
          <ul>
            <li>Deny you goods or services. </li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
            <li>
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <p className="text-justify">
            However, the company may offer you certain financial incentives
            permitted by the CCPA that can result in different prices, rates, or
            quality levels. Any CCPA-permitted financial incentive the company
            offers will reasonably relate to your personal information's value
            and contain written terms that describe the program's material
            aspects. Participation in a financial incentive program requires
            your prior opt in consent, which you may revoke at any time.
          </p>
          <h3>Other Important Information</h3>
          <p className="text-justify">
            This Service does not respond to a Web browsers _do not track_
            signal or similar mechanism. This site only collects personally
            identifiable information provided voluntarily by the Site user and
            submitted to us, as well as non-personally identifiable information
            via cookies or third party tracking services.
          </p>
          <h3>Contact Information</h3>
          <p className="text-justify">
            Questions concerns or comments regarding this Privacy Policy or any
            other aspects regarding privacy can be directed via certified mail
            or via our contact page.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
