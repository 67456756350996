import React from "react";

export default function FormHeading(props) {
  const { main, sub } = props;
  return (
    <div className="row">
      <div className="col pt-3 pb-3 text-center">
        <h2>{main}</h2>
        {sub ? <label>{sub}</label> : ""}
      </div>
    </div>
  );
}
