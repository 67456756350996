import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function HowItWorks(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>How It Works</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            A payday loan or cash advance may be the solution to your
            difficulties if you need money right away and there isn't a clear
            solution in sight. Our procedure is straightforward, secure, and
            practical:
          </p>
          <ul>
            <li>
              Complete and submit our quick and secure online loan application.
              After reviewing your information, our network of lenders will
              decide whether to extend you a loan.
            </li>
            <li>
              You will be shown the facts and paperwork for your loan, if it has
              been accepted by a lender.
            </li>
            <li>
              Examine your loan documentation online and make a copy. Your
              agreement might need to be signed electronically.
            </li>
            <li>
              After that, within one business day, your loan will be directly
              paid into your bank account.
            </li>
          </ul>
          <h4>Being Eligible for a Loan</h4>
          <p className="text-justify">
            Even if any form of credit may be accepted for your loan request,
            there are still a few standards that you must fulfil in order to be
            eligible.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
