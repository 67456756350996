import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import Joi from "joi";
import "./modal.css";
import { myAxios } from "../../library/networkFunction";
import { removeme_api } from "../../library/form_api";
import ErrorToolTip from "../../Component/ErrorToolTip";
import ErrorLabel from "../../Component/ErrorLabel";
export default function RemoveMe(props) {
  const [request, setRequest] = useState({});
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(null);
  const submitRemove = async () => {
    const schema = Joi.object({
      email: Joi.string()
        .trim()
        .required()
        .email({ tlds: { allow: false } }),
    });
    const obj = schema.validate(request, { abortEarly: false });
    let { error } = obj;
    if (error) {
      const { details } = error;
      const invalid = details.map(({ path }) => path[0]);
      const invalid_field = {};
      invalid.forEach((key) => {
        invalid_field[key] = true;
      });
      setError(invalid_field);
      return false;
    }
    // // Block list
    // // Remove the values you don't want
    const final_request = _.omit(request, ["agree"]);
    // // Allow list
    // // Only allow certain values
    // var result = _.pick(credentials, ["fname", "lname"]);
    const url = `${removeme_api}/`;
    const response = await myAxios({
      method: "post",
      url,
      request: final_request,
    });
    const { status } = response.data;
    if (status == 200) {
      setSuccess(true);
      // hide();
    } else alert("please try again");
  };
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Me</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <>
              <div className="row">
                <div className="col">
                  Please note that it can take up to 10 working days for our
                  affiliates to update their system{" "}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col">
                  Enter your email address below to unsubscribe
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <Form.Group className="mb-3">
                    {/* <Form.Label className="lf-label">
                  Email <ErrorLabel error={error} id="email" />
                </Form.Label> */}
                    <Form.Control
                      defaultValue={request["email"]}
                      className={error["email"] ? "val_error" : ""}
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      onChange={(e) => {
                        setRequest((request) => {
                          request["email"] = e.target.value;
                          return request;
                        });
                      }}
                    />
                  </Form.Group>{" "}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col text-end">
                  <Button onClick={submitRemove}>Unsubscribe</Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
