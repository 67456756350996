import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function Contact(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-justify">
            If you still have questions after reading our Frequently Asked
            Questions section, please do not hesitate to contact us below.
          </p>
          <p>
            Company Name: Dime In Time Media Address: 401, Ryland Street Suite
            <br />
            200-A, Reno, NV 89502, USA
          </p>
          <p>
            Please note that we are not a lender. Any questions pertaining to
            your loan should be directed to your lender; we have no information
            on your loan, your pending request, or your rates and fees.
          </p>
          {/* <p>To stop receiving marketing emails, click here.[Remove us] </p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
