import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-dropdown-select"; // https://react-dropdown-select.netlify.app/
import { DatePicker } from "antd";
import { months, states, homeOwner } from "../library/local_data";
import ErrorLabel from "../Component/ErrorLabel";
import Joi from "joi";
export default function Step1(props) {
  const { setRequest, error, blurAction, request, setError } = props;
  return (
    <>
      <div className="row mb-3 mt-3">
        <div className="col">
          <h4 className="lf-block-heading">Step 1</h4>
          <label>Tell us about yourself.</label>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              First Name
              <ErrorLabel error={error} id="firstName" />
            </Form.Label>
            <Form.Control
              className={error["firstName"] ? "val_error" : ""}
              // placeholder="First Name"
              aria-label="First Name"
              onChange={(e) => {
                setRequest((request) => {
                  request["firstName"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "firstName" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Last Name <ErrorLabel error={error} id="lastName" />
            </Form.Label>
            <Form.Control
              className={error["lastName"] ? "val_error" : ""}
              // placeholder="Last Name"
              aria-label="Last Name"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setRequest((request) => {
                  request["lastName"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "lastName" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Address <ErrorLabel error={error} id="streetAddress" />
            </Form.Label>
            <Form.Control
              className={error["streetAddress"] ? "val_error" : ""}
              // placeholder="Address"
              aria-label="Address"
              onChange={(e) => {
                setRequest((request) => {
                  request["streetAddress"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "streetAddress" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              City <ErrorLabel error={error} id="locality" />
            </Form.Label>
            <Form.Control
              className={error["locality"] ? "val_error" : ""}
              // placeholder="City"
              aria-label="City"
              onChange={(e) => {
                setRequest((request) => {
                  request["locality"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "locality" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              State <ErrorLabel error={error} id="region" />
            </Form.Label>
            <Select
              className={error["region"] ? "val_error" : ""}
              options={states}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["region"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "region" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Zip Code <ErrorLabel error={error} id="postalCode" />
            </Form.Label>
            <Form.Control
              className={error["postalCode"] ? "val_error" : ""}
              // placeholder="Zip Code"
              type="number"
              aria-label="Zip Code"
              onChange={(e) => {
                if (e.target.value.length <= 5) {
                  setRequest((request) => {
                    request["postalCode"] = e.target.value;
                    return request;
                  });
                } else e.target.value = request["postalCode"];
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().length(5).trim().required(),
                });
                blurAction({ name: "postalCode", schema });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Do you own or rent? <ErrorLabel error={error} id="homeOwner" />
            </Form.Label>
            <Select
              className={error["homeOwner"] ? "val_error" : ""}
              searchable={false}
              options={homeOwner}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["homeOwner"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "homeOwner" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Time at Residence <ErrorLabel error={error} id="timeAddress" />
            </Form.Label>
            <Select
              className={error["timeAddress"] ? "val_error" : ""}
              searchable={false}
              options={months}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["timeAddress"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "timeAddress" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Driver's License <ErrorLabel error={error} id="driverLicense" />
            </Form.Label>
            <Form.Control
              className={error["driverLicense"] ? "val_error" : ""}
              // placeholder="Driver's License"
              aria-label="Driver's License"
              onChange={(e) => {
                setRequest((request) => {
                  request["driverLicense"] = e.target.value;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "driverLicense" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Issuing State <ErrorLabel error={error} id="driverState" />
            </Form.Label>
            <Select
              className={error["driverState"] ? "val_error" : ""}
              options={states}
              labelField="label"
              valueField="value"
              default
              onChange={(values) => {
                if (values.length) {
                  setRequest((request) => {
                    request["driverState"] = values[0]["value"];
                    return request;
                  });
                }
              }}
              onDropdownClose={() => {
                blurAction({ name: "driverState" });
              }}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Date of Birth <ErrorLabel error={error} id="dob" />
            </Form.Label>
            <br />
            <DatePicker
              inputReadOnly={true}
              className={`date-picker ${error["dob"] ? "val_error" : ""}`}
              onChange={(date, dateString) => {
                var today = new Date();
                var birthDate = new Date(dateString);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
                  age--;
                if (age < 18) return alert("Must be at least 18 years old");
                setRequest((request) => {
                  request["dob"] = dateString;
                  return request;
                });
              }}
              onBlur={() => {
                blurAction({ name: "dob" });
              }}
            />
          </Form.Group>
        </div>
        <div className="col-lg-4 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Social Security Number <ErrorLabel error={error} id="SSN" />
            </Form.Label>
            <Form.Control
              type="number"
              className={error["SSN"] ? "val_error" : ""}
              // placeholder="Social Security Number"
              aria-label="Social Security Number"
              onChange={(e) => {
                if (e.target.value.length <= 9) {
                  setRequest((request) => {
                    request["SSN"] = e.target.value;
                    return request;
                  });
                } else e.target.value = request["SSN"];
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().length(9).trim().required(),
                });
                blurAction({ name: "SSN", schema });
              }}
            />
            <Form.Text className="text-muted">
              Your social security number is required for your protection and
              may be used to confirm your identity.
            </Form.Text>
          </Form.Group>
        </div>
        <div className="col-lg-4 col-12">
          <Form.Group className="mb-3">
            <Form.Label className="lf-label">
              Contact Number <ErrorLabel error={error} id="mobilePhone" />
            </Form.Label>
            <Form.Control
              className={error["mobilePhone"] ? "val_error" : ""}
              // placeholder="Contact Number"
              type="number"
              aria-label="Contact Number"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setRequest((request) => {
                    request["mobilePhone"] = e.target.value;
                    return request;
                  });
                } else e.target.value = request["mobilePhone"];
              }}
              onBlur={() => {
                const schema = Joi.object({
                  value: Joi.string().length(10).trim().required(),
                });
                blurAction({ name: "mobilePhone", schema });
              }}
            />
            {/* <Form.Text className="text-muted">
              By entering your phone number, you agree to receive calls,
              autodialed or artificial voices and pre recorded marketing text
              messages from marketing partners, lenders, subsidiaries, and/or
              agents. Consent is not required to purchase goods or services.
              Message and data rates may apply. Receive recurring monthly
              messages. Privacy Policy, and Terms & Conditions, and E-Consent To
              Submit form without providing consent to be contacted, click here.
            </Form.Text> */}
          </Form.Group>
        </div>
      </div>
    </>
  );
}
