import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function EConsent(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            The Credit Lending Consent for electronic signatures, records, and
            disclosures ("E-Consent")
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>
            CONSENT FOR ELECTRONIC SIGNATURES, RECORDS, AND DISCLOSURES
            ("E-Consent")
          </h2>
          <p className="text-justify">
            Please read this information carefully and print a copy and/or
            retain this information for future reference.
          </p>
          <h3>Introduction</h3>
          <p className="text-justify">
            You are submitting a request to be connected with one of our
            third-party lenders. In order to offer you a loan, the third-party
            lenders need your consent to use and accept electronic signatures,
            records, and disclosures ("E-Consent"). This form notifies you of
            your rights when receiving electronic disclosures, notices, and
            information. By clicking on the link assenting to our terms, you
            acknowledge that you received this E-Consent and that you consent to
            conduct transactions using electronic signatures, electronic
            disclosures, electronic records, and electronic contract documents
            ("Disclosures").
          </p>
          <h3>Option for Paper or Non-Electronic Records</h3>
          <p className="text-justify">
            You may request any Disclosures in paper copy by contacting the
            third-party lender directly. The lenders may provide paper copies to
            you. Some may charge for these copies and some may not. The lenders
            will retain all Disclosures as applicable law requires.
          </p>
          <h3>Scope of Consent</h3>
          <p className="text-justify">
            This E-Consent applies to all interactions online concerning you and
            the third-party lender and includes those interactions engaged in on
            any mobile device, including phones, smart-phones, and tablets. By
            exercising this E-Consent, the third-party lender may process your
            information and interact during all online interactions with you
            electronically. The lender may also send you notices electronically
            related to its interactions and transactions. Disclosures may be
            provided online at our or third-party lenders' websites, and may be
            provided by e-mail.
          </p>
          <h3>Consenting to Do Business Electronically</h3>
          <p className="text-justify">
            Before you decide to do business electronically with the third-party
            lenders, you should consider whether you have the required hardware
            and software capabilities described below.
          </p>
          <h3>Hardware and Software Requirements</h3>
          <p className="text-justify">
            To access and retain the Disclosures electronically, you will need
            to use the following computer software and hardware: A PC or MAC
            compatible computer or other device capable of accessing the
            Internet, access to an e-mail account, and an Internet Browser
            software program that supports at least 128-bit encryption, such as
            Microsoft® Internet Explorer, Chrome® or Mozilla Firefox®. To read
            some documents, you may need a PDF file reader like Adobe® Acrobat
            Reader X®. You will need a printer or a long-term storage device,
            such as your computer's disk drive, to retain a copy of the
            Disclosures for future reference. You may send any questions
            regarding the hardware and software requirements directly to the
            third-party lenders.
          </p>
          <h3>Withdrawing Consent</h3>
          <p className="text-justify">
            Your E-Consent for our connecting service and for our third-party
            lenders' consideration of your connecting request cannot be
            withdrawn because it is a one-time transaction. If you are connected
            with one or more third party lenders, you are free to withdraw your
            E-Consent with those third-party lenders at any time and at no
            charge. However, if you withdraw this E-Consent before receiving
            credit, you may be prevented from obtaining credit from the lender.
            Contact the third-party lender directly if you wish to withdraw this
            E-consent. If you decide to withdraw this E-Consent, the legal
            effectiveness, validity, and enforceability of prior electronic
            Disclosures will not be affected.
          </p>
          <h3>Change to Your Contact Information</h3>
          <p className="text-justify">
            You should keep third party lenders informed of any change in your
            electronic address or mailing address. You may update such
            information by logging into the third-party lender's website or by
            sending the lender a written update by mail.
          </p>
          <h3>YOUR ABILITY TO ACCESS DISCLOSURES</h3>
          <p className="text-justify">
            BY SUBMITTING YOUR INFORMATION YOU AGREE TO, YOU ASSENT TO OUR
            TERMS. YOU ACKNOWLEDGE THAT YOU CAN ACCESS THE DISCLOSURES IN THE
            DESIGNATED FORMATS DESCRIBED ABOVE. CONSENT. BY SUBMITTING YOUR
            INFORMATION YOU AGREE TO, YOU ASSENT TO THE TERMS. YOU ACKNOWLEDGE
            YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS,
            DISCLOSURES, AND DOING BUSINESS ELECTRONICALLY. YOU CONSENT TO USING
            ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE
            AVAILABLE TO YOU IN ELECTRONIC FORM AND TO DOING BUSINESS WITH THE
            LENDER ELECTRONICALLY. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER
            COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WILL BE
            PROVIDED TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN
            YOU NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS
            TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE THAT YOUR CONSENT
            TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE SERVICES FROM THIRD
            PARTY LENDERS OVER THE INTERNET.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
