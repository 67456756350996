import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./modal.css";
export default function Faq(props) {
  const { show, hide } = props;
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Frequently Asked Questions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Is my personal and financial information secure?</h3>
          <p className="text-justify">
            We always respect your right to privacy with regards to your
            personal finances. This website uses various security systems to
            maintain the safety of your personal information. All personal and
            sensitive data is transmitted between your computer's browser and
            our website uses 128 bit Secure Socket Layer (SSL) encryption
            technology.
          </p>
          <h3>What if I have bad credit? </h3>
          <p className="text-justify">
            We attempt to connect consumers with lenders regardless of your
            credit history. Requirements needed for a loan approval is based on
            each individual lender. Please review our How It Works page to
            determine if you have the basic requirements to qualify.
          </p>
          <h3>What is an installment loan or cash advance? </h3>
          <p className="text-justify">
            An Installment Loan allows you to pay for your loan over a longer
            period of time than your traditional Payday Loan. The lender's
            Installment Loan products allows you to repay the loan in scheduled
            payments that are generally equal in amount (last payment may vary
            slightly).
          </p>
          <h3>What information do I need to complete the form? </h3>
          <p className="text-justify">
            We require your name, address, income information and banking
            details in order to complete your loan request.
          </p>
          <h3>
            How quickly can I get the money deposited into my checking account?
          </h3>
          <p className="text-justify">
            Usually, funds will be deposited into your account the next business
            day. However, sometimes some lenders may need to ask you for
            additional information based on your circumstances resulting in
            additional days for the deposit.
          </p>
          <h3>What are the requirements for an installment loan? </h3>
          <p className="text-justify">
            To be approved for a loan, you need to meet the individual
            requirements of the specific lender you are connected to. The lender
            will evaluate your form and decide if you are eligible based on its
            own established list of considerations such as income, direct
            deposit of your pay, past loan history and state of residence, may
            influence the approval of your loan.
          </p>
          <p className="text-justify">
            A few requirements include but are not limited to:
            <ul>
              <li>Have a valid checking or savings account</li>
              <li>Be 18 years of age</li>
              <li>Be a U.S. citizen or permanent resident</li>
              <li>Minimum regular income of $800 per month.</li>
            </ul>
          </p>
          <p className="text-justify">
            If your loan form is accepted by a lender, your appointed lender may
            contact you directly and ask for extra information in order to
            approve your loan form. Installment lenders utilize their own
            specialized selection methods to assess applicants and will approve
            your loan if your information meets their criteria. You must
            complete our form with truthful certifiable information to improve
            your chances of getting connected with a lender. For example,
            entering a cell phone number instead of a home phone reduces your
            chances of your information being verified by the lender. However,
            we cannot guarantee that your loan form will be accepted.
          </p>
          <h3>Where do I find my ABA / Routing Number & account number? </h3>
          <p className="text-justify">
            Please see the diagram below for instructions on how to find the
            information from your check book. Alternatively please call your
            bank for more information.
          </p>
          <h3>How long do I have to pay back the loan? </h3>
          <p className="text-justify">
            Your Installment Loan payments will be due on consecutive pay
            periods and the term (length) of your loan will vary by state. You
            will be informed of the payment due dates and amounts when your loan
            is approved.
          </p>
          <h3>How do I pay back the loan? </h3>
          <p className="text-justify">
            If you complete the form, you agree to allow the Lender to initiate
            a transfer of funds from your bank account on the day the payment is
            due.
          </p>
          <p className="text-justify">
            If you take out an installment loan and pay it off on time, this may
            be reported to a Credit Reference Agency. If you show a pattern of
            taking out a loan and always repaying the loan under the agreed
            timeframe, this may have a beneficial impact on your credit record.
          </p>
          <h3>What happens if I pay back my loan late? </h3>
          <p className="text-justify">
            Late payments of loans may result in additional fees or collection
            activities, or perhaps both.
          </p>
          <p className="text-justify">
            Each Lender has their own terms and conditions, please be sure to
            look through their policies for additional information.
          </p>
          <h3>What happens if I do not pay back the loan?</h3>
          <p className="text-justify">
            Not repaying your credit could result in collection activities.
          </p>
          <p className="text-justify">
            Each Lender has their own terms and conditions, please be sure to
            look through their policies for additional information.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
