import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import HowItWorks from "./Modals/HowItWorks";
import AdvertiserDisclosure from "./Modals/AdvertiserDisclosure";
import CaliforniaPrivacyNotice from "./Modals/CaliforniaPrivacyNotice";
import Contact from "./Modals/Contact";
import RemoveMe from "./Modals/RemoveMe";
import EConsent from "./Modals/EConsent";
import Faq from "./Modals/Faq";
import PrivacyPolicy from "./Modals/PrivacyPolicy";
import RatesFees from "./Modals/RatesFees";
import TermsConditions from "./Modals/TermsConditions";
import ShortForm from "./ShortForm";
import LongForm from "./LongForm";
import Thanks from "./Thanks";
import HomePagePart from "./HomePagePart";
export default function Home() {
  const [short_id, set_short_id] = useState(null);
  const [show_short, set_show_short] = useState(true);
  const [show_long, set_show_long] = useState(false);
  const [show_thanks, set_show_thanks] = useState(false);
  const [how_it_works, set_how_it_works] = useState(false);
  const [advertiser_disclosure, set_advertiser_disclosure] = useState(false);
  const [california_privacy_notice, set_california_privacy_notice] =
    useState(false);
  const [contact, set_contact] = useState(false);
  const [econsent, set_econsent] = useState(false);
  const [faq, set_faq] = useState(false);
  const [privacy_policy, set_privacy_policy] = useState(false);
  const [rates_fees, set_rates_fees] = useState(false);
  const [remove_me, set_remove_me] = useState(false);
  const [terms_conditions, set_terms_conditions] = useState(false);
  return (
    <>
      <Navbar bg="light" expand="lg">
        {/* <Container> */}
        <Navbar.Brand>
          <img className="nav-logo" src="./logo.png" />
          {/* The Credit Lending */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              onClick={() => {
                set_how_it_works(true);
              }}
            >
              HOW IT WORKS
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                set_rates_fees(true);
              }}
            >
              RATES & FEES
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                set_faq(true);
              }}
            >
              FAQ'S
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                set_advertiser_disclosure(true);
              }}
            >
              ADVERTISING DISCLOSURE
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                set_contact(true);
              }}
            >
              CONTACT
            </Nav.Link>
            {/* <Nav.Link>
              <img className="top-seal-ola" src="./seal-ola.png" />
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
      <div className="container-fluid">
        {show_short ? (
          <>
            <ShortForm
              set_econsent={set_econsent}
              set_advertiser_disclosure={set_advertiser_disclosure}
              set_privacy_policy={set_privacy_policy}
              set_terms_conditions={set_terms_conditions}
              setData={(short_id) => {
                set_short_id(short_id);
                set_show_short(false);
                set_show_long(true);
              }}
            />
            <HomePagePart />
          </>
        ) : (
          ""
        )}
        {show_long && short_id ? (
          <LongForm
            short_id={short_id}
            setData={() => {
              set_show_long(false);
              console.log("yes");
              set_show_thanks(true);
            }}
          />
        ) : (
          ""
        )}
        {show_thanks ? <Thanks /> : ""}
        <div className="row justify-content-center p-5">
          <div className="col-lg-10 col-12">
            <p className="text-justify">
              THE OPERATOR OF THIS WEBSITE is a for-profit advertising network.
              Lenders, aggregators, credit repair businesses, or other offers
              that we refer you to pay us money in the form of referral fees.
              Therefore, which offer you are presented with may depend on the
              amount of compensation offered in addition to other
              considerations. The company that made the highest bid for your
              information may be the one making the offer to you. You may not
              always receive an offer from the owner of this website with the
              best prices or conditions. Not all businesses or deals are
              represented on our website. We urge you to look into all of the
              financing possibilities that are available for your circumstance.
            </p>
            <p className="text-justify">
              * The owner of this website does not decide whether to grant
              credit or cash advances, nor does she act as a loan broker for
              lenders. There is no lending offer or solicitation on this
              website. The data you give will be forwarded to a lender via this
              website. You could occasionally be given the choice to get a loan
              from a tribal lender. Tribal lenders are exempt from state law,
              including usury limitations, but are subject to specific tribal
              and federal legislation. Please be aware that if you are connected
              to a tribal lender, the rates and fees may be higher than those of
              state-licensed lenders, and you might be asked to consent to
              having any disagreements resolved in a tribal court. Your data can
              also be sent to an aggregator rather than a lender. Your data may
              be sold more than once, resulting in more than one offer from
              lenders, aggregators, and other marketers. It is not a given that
              you will be granted a cash advance if you submit your information
              on this website. The owner of this website is not a lender's
              agent, representative, or broker, and they do not recommend or
              charge you for any good or service. Some lenders have a maximum
              $2,500 credit limit. Depending on your specific financial
              institution, cash transfer timings may differ amongst lenders. In
              some cases, faxing can be necessary. The states that are served by
              this website may vary from time to time and without prior notice,
              and this service is not available in all of them. Please get in
              touch with your lender immediately if you have any questions or
              issues about your cash advance. Cash advances are not intended to
              be long-term fixes, but rather short-term loans to address your
              immediate cash demands. Due to lender rules, residents of several
              states could not be eligible for a cash advance.
            </p>
            <p className="text-justify">
              ** Experian, Equifax, or Trans Union are the three credit
              reporting bureaus through which lenders may conduct credit checks.
              Some lenders may acquire credit checks or consumer reports from
              other vendors.
            </p>
            <p className="text-justify">
              ANTI-SPAM POLICY: We vehemently forbid any mention or promotion of
              our company name or website through unsolicited email
              communications. The relationship will be terminated for violating
              this policy, and any other legal remedies will be available.
              Please see our Privacy Policy if you believe you have received
              unsolicited messages promoting our company or website and would
              like to file a complaint. All complaints will be looked into, and
              any required action will be taken.
            </p>
          </div>
        </div>
        <div className="row justify-content-center  bg-light">
          <div className="col-lg-4 col-12" style={{ minHeight: "100px" }}>
            <div className="row">
              <div className="col footer-col">
                <span>
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_how_it_works(true);
                    }}
                  >
                    How It Works
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_rates_fees(true);
                    }}
                  >
                    Rates & Fees
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_faq(true);
                    }}
                  >
                    FAQ'S
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_advertiser_disclosure(true);
                    }}
                  >
                    Advertising Disclosure
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_california_privacy_notice(true);
                    }}
                  >
                    Do Not Sell My Personal Information
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_contact(true);
                    }}
                  >
                    Contact
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_remove_me(true);
                    }}
                  >
                    Remove Me
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_privacy_policy(true);
                    }}
                  >
                    Privacy Policy
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_california_privacy_notice(true);
                    }}
                  >
                    California Privacy Notice
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_terms_conditions(true);
                    }}
                  >
                    Terms & Conditions
                  </a>
                </span>
                <span>
                  •
                  <a
                    className="footer-links"
                    onClick={() => {
                      set_econsent(true);
                    }}
                  >
                    E-Consent
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row  bg-light ">
          <div
            className="col p-5"
            style={{ height: "50px", textAlign: "center" }}
          >
            Copyright ©2023 The Credit Lending. All rights reserved.
          </div>
        </div>
      </div>
      <HowItWorks
        show={how_it_works}
        hide={() => {
          set_how_it_works(false);
        }}
      />
      <AdvertiserDisclosure
        show={advertiser_disclosure}
        hide={() => {
          set_advertiser_disclosure(false);
        }}
      />
      <CaliforniaPrivacyNotice
        show={california_privacy_notice}
        hide={() => {
          set_california_privacy_notice(false);
        }}
      />
      <Contact
        show={contact}
        hide={() => {
          set_contact(false);
        }}
      />
      <EConsent
        show={econsent}
        hide={() => {
          set_econsent(false);
        }}
      />
      <Faq
        show={faq}
        hide={() => {
          set_faq(false);
        }}
      />
      <PrivacyPolicy
        show={privacy_policy}
        hide={() => {
          set_privacy_policy(false);
        }}
      />
      <RatesFees
        show={rates_fees}
        hide={() => {
          set_rates_fees(false);
        }}
      />
      <RemoveMe
        show={remove_me}
        hide={() => {
          set_remove_me(false);
        }}
      />
      <TermsConditions
        show={terms_conditions}
        hide={() => {
          set_terms_conditions(false);
        }}
      />
    </>
  );
}
