import React from "react";

export default function ErrorLabel(props) {
  let { message, error, id } = props;

  if (typeof error[id] == "string") message = error[id];
  return error[id] ? (
    <label
      style={{
        color: "red",
        marginLeft: "5px",
      }}
    >
      {message ? message : "This is required field"}
    </label>
  ) : (
    ""
  );
}
